import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/layout";
import { Seo } from "../../components/shared/seo";
import { MediumsNavbar } from "../../components/mediums/mediumsNavbar";
import { LinkTypeBig } from "../../components/shared/links";
const MediumsPage = (props) => {
  // --->  Data <--- //
  const mediums = props.data.allStrapiMedium.nodes;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <MediumsNavbar />
      <div className="flex flex-wrap xs:gap-x-xl xs:gap-y-md md:gap-x-xxl md:gap-y-xl">
        {mediums.map((medium, index) => {
          return (
            <LinkTypeBig
              slug={`medium`}
              type={medium.mediumType.toLowerCase()}
              key={index}
              isMedium={true}
              classes={"last:mr-0"}
            />
          );
        })}
      </div>
    </Layout>
  );
};
export const query = graphql`
  query MediumsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiMedium {
      nodes {
        mediumType
      }
    }
  }
`;

export default MediumsPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Projects"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
