import React from "react";
import { Link } from "gatsby";
import { ArrowRegIcon } from "../../images/icons";
import { LinkIcon } from "../shared/links";
import {
  responsiveIconsBasic,
  responsiveTextBasic,
  stickyNav,
} from "../shared/styles";

export const MediumsNavbar = ({ medium }) => {
  return (
    <div
      className={`flex mb-xl ${stickyNav} ${responsiveTextBasic} bg-yellow-50 z-[29]`}
    >
      {medium ? (
        <div className="flex items-center mr-5">
          <Link
            className={` ${responsiveTextBasic} capitalize mr-5`}
            to={`/mediums`}
          >
            <LinkIcon>Mediums</LinkIcon>
          </Link>
          <ArrowRegIcon classes={`${responsiveIconsBasic} h-[1rem]`} />
        </div>
      ) : (
        <div>Mediums:</div>
      )}
      <div className="opacity-50 capitalize">{medium && medium}</div>
    </div>
  );
};
